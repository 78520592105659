.zoomHover {
  transition: transform .2s; /* Animation */
}
.zoomHover:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.transition-all {
  transition: all 1s ease;
}

.fade-in {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
}


.fade-out {
    animation: fadeOut ease 0.6s;
    -webkit-animation: fadeOut ease 0.6s;
    -moz-animation: fadeOut ease 0.6s;
    -o-animation: fadeOut ease 0.6s;
    -ms-animation: fadeOut ease 0.6s;
  }
  @keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-moz-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-o-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-ms-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
  }
}


.fade-in-50 {
    animation: fadeIn-50 ease 2s;
    -webkit-animation: fadeIn-50 ease 2s;
    -moz-animation: fadeIn-50 ease 2s;
    -o-animation: fadeIn-50 ease 2s;
    -ms-animation: fadeIn-50 ease 2s;
  }
  @keyframes fadeIn-50 {
    0% {
      opacity:0;
    }
    100% {
      opacity:0.5;
    }
  }
  
  @-moz-keyframes fadeIn-50 {
    0% {
      opacity:0;
    }
    100% {
      opacity:0.5;
    }
  }
  
  @-webkit-keyframes fadeIn-50 {
    0% {
      opacity:0;
    }
    100% {
      opacity:0.5;
    }
  }
  
  @-o-keyframes fadeIn-50 {
    0% {
      opacity:0;
    }
    100% {
      opacity:0.5;
    }
  }
  
  @-ms-keyframes fadeIn-50 {
    0% {
      opacity:0;
    }
    100% {
      opacity:0.5;
  }
}


.fade-out-50 {
    animation: fadeOut-50 ease 0.6s;
    -webkit-animation: fadeOut-50 ease 0.6s;
    -moz-animation: fadeOut-50 ease 0.6s;
    -o-animation: fadeOut-50 ease 0.6s;
    -ms-animation: fadeOut-50 ease 0.6s;
  }
  @keyframes fadeOut-50 {
    0% {
      opacity:0.5;
    }
    100% {
      opacity:0;
    }
  }
  
  @-moz-keyframes fadeOut-50 {
    0% {
      opacity:0.5;
    }
    100% {
      opacity:0;
    }
  }
  
  @-webkit-keyframes fadeOut-50 {
    0% {
      opacity:0.5;
    }
    100% {
      opacity:0;
    }
  }
  
  @-o-keyframes fadeOut-50 {
    0% {
      opacity:0.5;
    }
    100% {
      opacity:0;
    }
  }
  
  @-ms-keyframes fadeOut-50 {
    0% {
      opacity:0.5;
    }
    100% {
      opacity:0;
  }
}